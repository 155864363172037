<template>
  <div class="select">
    <div class="cont" @click="selectShow">
      <span v-if="!value" style="color: #d3d3d3">请输入内容</span>
      <span v-else>
        {{ value }}
      </span>
    </div>
    <div v-show="display" class="option" @mouseleave="display = false">
      <div v-for="(item, key) in containerList" :key="key" class="option-list">
        <div>{{ item.cabinetType }}</div>
        <div>
          <el-input
            v-model.number="item.cabinetNum"
            clearable
            max="5"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入内容"
            style="width: 100px"
          />
          <!-- <el-input
            v-model="item.cabinetNum"
            style="width: 100px"
            placeholder="请输入内容"
            oninput="value=value.replace(/[^0-9]/g,'')"
            max='5'
            @blur="item.value = $event.target.value"
          ></el-input> -->
        </div>
      </div>
      <div style="float: right; margin-top: 10px">
        <el-button type="primary" @click="determine">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        containerList: [
          {
            cabinetType: '20GP',
            cabinetNum: '',
          },
          {
            cabinetType: '40GP',
            cabinetNum: '',
          },
          {
            cabinetType: '40HQ',
            cabinetNum: '',
          },
          {
            cabinetType: '45HQ',
            cabinetNum: '',
          },
        ],
        value: '',
        display: false,
      }
    },
    methods: {
      selectShow() {
        this.display = !this.display
      },
      determine() {
        let val = []
        let arr = []
        for (const item of this.containerList) {
          console.log(item)
          if (item.cabinetNum > 0) {
            val.push(item.cabinetType + '*' + item.cabinetNum)
            arr.push(item)
          }
        }
        this.value = val.join(', ')
        console.log(arr)
        this.$emit('selectInputChang', arr)
        this.selectShow()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .select {
    position: relative;
  }
  .cont {
    display: inline-block;
    min-width: 120px;
    height: 32px;
    padding: 0 12px;
    line-height: 32px;
    border: 1px solid #d3d3d3;
    user-select: none;
    cursor: pointer;
  }
  .option {
    width: 240px;
    transform-origin: center top;
    z-index: 2031;
    position: absolute;
    top: 40px;
    background: #fff;
    border: 1px solid #f3f3f3;
    padding: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    // display: flex;
  }
  .option-list {
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    margin-bottom: 7px;
  }
</style>
>
