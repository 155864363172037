<template>
  <div class="fomrDisply">
    <div>
      <el-input
        v-model.trim="form.weight"
        @input="inputClick"
        clearable
        style="width: 200px"
        oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/,'$1')"
        @blur="inputClick"
        slot="reference"
      >
        <template slot="append">&nbsp;&nbsp;&nbsp;T&nbsp;&nbsp;&nbsp;</template>
      </el-input>
      <!-- <el-popover placement="bottom" width="160" v-model="visible">
        <p>警告：</p>
        <p>新解析的值将覆盖已存在的值</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="visible = false">
            取消
          </el-button>
          <el-button type="primary" size="mini" @click="weightData()">
            确定
          </el-button>
        </div>
      </el-popover> -->
    </div>
    <div style="padding-left: 10px">
      <el-input
        clearable
        placeholder="请输入数字和运算符，乘号只能用*"
        class="input-with-select"
        v-model.trim="form.originalWeight"
        oninput="value=value.replace(/[^0-9+().*%/-]/g,'')"
        @blur="form.originalWeight = $event.target.value"
        @keyup.enter.native="inputChangeOne"
        style="width: 250px"
      ></el-input>
      <!-- @blur="form.originalWeight = $event.target.value" -->
      <el-select
        slot="prepend"
        placeholder="请选择"
        style="width: 80px"
        v-model="form.originalWeightUnit"
        @change="inputChangeOne"
      >
        <el-option label="KG" value="KG"></el-option>
        <el-option label="T" value="T"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   number: Number,
      //   originalWeight: String,
      visible: false,

      form: {
        weightUnit: 'T',
        weight: '',
        originalWeight: '',
        originalWeightUnit: 'T',
      },
    }
  },
  //   props: {
  //     'weight': {
  //         type: Number,
  //         debugger: 0
  //     },
  //     'originalWeight': {
  //         type: String,
  //         debugger: ''
  //     }
  //   },
  methods: {
    inputChangeOne() {
      // if (this.form.weight == '' || this.form.weight == undefined) {
        this.weightData()
      // } else if (this.form.originalWeight != '') {
        // this.visible = true
      // }
    },
    weightData() {
      if (
        this.form.originalWeightUnit == 'T' &&
        this.form.originalWeight != ''
      ) {
        this.form.weight = eval(this.form.originalWeight).toFixed(3)
      } else if (this.form.originalWeight != '') {
        this.form.weight = (eval(this.form.originalWeight) / 1000).toFixed(3)
      }
      this.visible = false
      this.$emit('totalCalculation', this.form)
    },
    inputClick() {
      this.$emit('totalCalculation', this.form)
    },
  },
}
</script>

<style>
.fomrDisply {
  display: flex;
}
</style>