<template>
  <div>
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="form"
          :rules="rules"
          @submit.native.prevent
        >
          <div>
            <el-form-item label="客户" prop="customer">
              <el-autocomplete
                v-model="form.customer"
                class="inline-input"
                clearable
                :fetch-suggestions="querySearchKe"
                placeholder="请输入内容"
                style="width: 150px"
                @select="handleSelectKe"
              >
                <template slot-scope="{ item }">
                  <div>
                    {{ item.name }}
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="业务员" prop="salesmanId">
              <el-select
                v-model="form.salesmanId"
                clearable
                filterable
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option
                  v-for="item in optionList.salesmanGetValidList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="" label-width="60px" prop="remark">
              <div style="margin-left: 15px">
                备注
                <el-tooltip
                  class="item"
                  content="询价信息的补充"
                  effect="dark"
                  placement="top"
                >
                  <i class="el-icon-warning"></i>
                </el-tooltip>
                <el-input
                  v-model="form.remark"
                  maxlength="200"
                  placeholder="请输入内容"
                  :rows="1"
                  style="width: 330px"
                  type="textarea"
                />
              </div>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="" prop="pick.uqKey">
              <div style="margin-left: 5px; display: inline">
                提货地址
                <el-tooltip
                  class="item"
                  content="“东莞市/中山市/东沙群岛/嘉峪关市/晋城市/济源市，这类特殊地址只能解析为市辖区。若需更详细的地址，请手动选择”"
                  effect="dark"
                  placement="top"
                >
                  <i class="el-icon-warning"></i>
                </el-tooltip>
                <el-autocomplete
                  v-model="form.pick.place"
                  :fetch-suggestions="querySearch"
                  placeholder="输入待解析的地址"
                  :popper-append-to-body="false"
                  popper-class="my-autocomplete"
                  style="width: 150px"
                  @change="hendQuery(form.pick.place)"
                  @select="handleSelect($event)"
                >
                  <template slot-scope="{ item }">
                    <div style="color: #409eff">
                      {{ item.name }}
                    </div>
                    <div class="name" style="font-size: 10px">
                      {{ item.cityName
                      }}{{ item.areasName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                        item.address
                      }}
                    </div>
                    <span class="addr"></span>
                    <span class="addr"></span>
                  </template>
                </el-autocomplete>
              </div>
              <el-select
                v-model="form.pick.uqKey"
                :filter-method="areasFilterOne"
                filterable
                placeholder="请选择"
                style="margin: 0px 5px; width: 200px"
                @change="onSelectAreasList(form.pick.uqKey)"
              >
                <el-option
                  v-for="item in areasListOne"
                  :key="item.uqKey"
                  :label="item.fullName"
                  :value="item.uqKey"
                />
              </el-select>
              <!-- <el-popover placement="bottom" width="160" v-model="visibleThree">
                <p>警告：</p>
                <p>新解析的值将覆盖已存在的值</p>
                <div style="text-align: right; margin: 0">
                  <el-button
                    size="mini"
                    type="text"
                    @click="visibleThree = false"
                  >
                    取消
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="placeClickOne()"
                  >
                    确定
                  </el-button>
                </div>
              </el-popover> -->
              <el-input
                v-model="form.pick.address"
                clearable
                maxlength="100"
                placeholder="详细地址"
                style="width: 200px"
              />
            </el-form-item>
            <el-form-item label="起运港" prop="dispatch">
              <el-select
                v-model="form.dispatch"
                clearable
                filterable
                placeholder="请选择"
                style="width: 130px"
              >
                <el-option
                  v-for="item in optionList.portList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="柜型柜量" prop="cabinetList">
              <select-input
                :key="datekey"
                @selectInputChang="selectInputChang"
              />
            </el-form-item>
          </div>
          <!-- <el-form-item label="包装方式" prop="packagingMethodId">
            <el-select
              v-model="form.packagingMethodId"
              filterable
              style="width: 180px"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionList.getValidList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item v-show="fold" label="重量" prop="weightMap">
            <!-- <automatic-calculation
              @totalCalculation="totalCalculation"
              :key="datekey"
            ></automatic-calculation> -->
            <trailer-automatic-calculation
              :key="datekey"
              @totalCalculation="totalCalculation"
            />
          </el-form-item>

          <!-- <el-form-item
            label="体积"
            v-show="fold"
            prop="volumeMap"
            label-width="60px"
          >
            <volume-calculation
              @totalCalculation2="totalCalculation2"
              :key="datekey"
            ></volume-calculation>
          </el-form-item> -->

          <el-form-item>
            <el-button type="text" @click="handleFold">
              <span v-if="fold">合并</span>
              <span v-else>展开</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': !fold }"
                icon="arrow-up-s-line"
              />
            </el-button>
          </el-form-item>
          <el-button type="primary" @click="queryList">查询</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      class="table eleChange"
      :data="list"
      element-loading-text="拼命加载中"
      header-cell-class-name="table-header"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="提货地址">
        <el-table-column align="center" label="起运市" prop="pickCityName" />
        <el-table-column align="center" label="起运区" prop="pickAreasName" />
        <el-table-column
          align="center"
          label="起运街道"
          prop="pickStreetName"
        />
      </el-table-column>
      <el-table-column align="center" label="送货地址">
        <el-table-column
          align="center"
          label="起运港"
          prop="dispatchPortName"
        />
      </el-table-column>
      <el-table-column align="center" label="总销售价" width="120">
        <template #default="{ row }">
          <template v-if="row.edit">
            <el-input
              v-model.trim="row.totalSellPrice"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              @blur="row.totalSellPrice = $event.target.value"
              @keyup.enter.native="confirmEdit(row)"
            />
            <!-- <el-button
              class="cancel-btn"
              type="warning"
              @click="cancelEdit(row)"
            >
              取消
            </el-button> -->
          </template>
          <span v-else>{{ row.totalSellPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="总标准价" prop="standard" />
      <el-table-column align="center" label="标准价">
        <el-table-column align="center" label="20GP" prop="standard20gp" />
        <el-table-column align="center" label="40GP" prop="standard40gp" />
        <el-table-column align="center" label="40HQ" prop="standard40hq" />
        <el-table-column align="center" label="45HQ" prop="standard45hq" />
      </el-table-column>
      <el-table-column
        align="center"
        label="是否含税"
        prop="includingTaxesName"
      />
      <el-table-column
        align="center"
        label="附加费"
        prop="surcharge"
        show-overflow-tooltip
        width="350"
      />
      <el-table-column align="center" label="时效" prop="ageing" />
      <el-table-column
        align="center"
        label="备注"
        prop="remark"
        show-overflow-tooltip
        width="350"
      />
      <el-table-column align="center" label="生效日" prop="effectiveDate" />
      <el-table-column align="center" label="失效日" prop="expiringDate" />
      <el-table-column align="center" label="产品编号" prop="code" />
      <el-table-column align="center" label="询价编号" prop="inquiryCode" />
      <el-table-column
        align="center"
        fixed="right"
        label="产品专员"
        prop="productCommissioner"
      />

      <!-- <el-table-column
        align="center"
        prop="dealCustomerList"
        label="头部成交客户"
      /> -->
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template #default="{ row }">
          <el-button
            v-if="row.edit"
            size="small"
            type="success"
            @click="confirmEdit(row)"
          >
            保存
          </el-button>
          <el-button
            v-else
            icon="el-icon-edit"
            size="small"
            type="primary"
            @click="row.edit = !row.edit"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <!-- <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    /> -->
  </div>
</template>
<script>
  import {
  areasPlaceSuggestion,
  trailCarCostProductSelectEdit,
} from '@/api/inquiryManagement'
import { trailCarServiceProductSelect } from '@/api/productCenter'
import selectInput from '@/components/selectInput'
import trailerAutomaticCalculation from '@/components/trailerAutomaticCalculation'
import volumeCalculation from '@/components/volumeCalculation'
  export default {
    components: {
      trailerAutomaticCalculation,
      volumeCalculation,
      selectInput,
    },
    props: {
      optionList: Object,
    },
    data() {
      return {
        fold: true,
        listLoading: false,
        height: this.$baseTableHeight(2),
        visibleThree: false,
        list: [],
        datekey: Date.now(),
        form: {
          customer: '',
          salesmanId: '',
          remark: '',
          pick: {
            provinceId: '',
            province: '',
            cityId: '',
            city: '"',
            areas: '',
            areasId: '',
            address: '',
            place: '',
            street: '',
            streetId: '',
            uqKey: '',
          },
          dispatch: '',
          cabinetList: [],
          packagingMethodId: '',
          weightMap: {
            weight: '',
            weightUnit: '',
            originalWeight: '',
            originalWeightUnit: '',
          },
          volumeMap: {
            volume: '',
            volumeUnit: '',
            originalVolume: '',
            originalVolumeUnit: '',
          },
          // pageNum: 1,
          // pageSize: 10,
        },
        rules: {
          // customer: [
          //   { required: true, trigger: 'blur', message: '请输入客户' },
          //   { required: true, trigger: 'change', message: '请输入客户' },
          // ],
          // salesmanId: [
          //   { required: true, trigger: 'change', message: '请选择业务员' },
          // ],
          pick: {
            uqKey: [
              {
                required: true,
                trigger: 'change',
                message: '请选择提货地址',
              },
              {
                required: true,
                trigger: 'blur',
                message: '请选择提货地址',
              },
            ],
          },
          // pick: [
          //   { required: true, trigger: 'change', message: '请选择提货地址' },
          // ],
          dispatch: [
            { required: true, trigger: 'change', message: '请选择起运港' },
          ],
          cabinetList: [
            { required: true, trigger: 'blur', message: '请输入柜型柜量' },
            { required: true, trigger: 'change', message: '请输入柜型柜量' },
          ],
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        cbFun: null,
        placeObject: {}, //解析地址
        placeListOne: [],
        areasListOne: [], //提货地址
        placeOne: '', //提货解析地址
      }
    },
    mounted() {},
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    methods: {
      querySearchKe(queryString, cb) {
        var restaurants = this.optionList.costomerGetValidList
        var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants
        // 调用 callback 返回建议列表的数据
        cb(results)
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (
            restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0
          )
        }
      },
      handleSelectKe(val) {
        this.form.customer = val.name
      },
      areasFilterOne(query = '') {
        let arr = this.optionList.areasList.filter((item) => {
          return item.fullName.includes(query) || item.fullName.includes(query)
        })
        if (arr.length > 150) {
          this.areasListOne = arr.slice(0, 150)
        } else {
          this.areasListOne = arr
        }
      },
      addValueOptions(areasId = '') {
        const targetList = this.optionList.areasList.filter((item) => {
          return item.areasId === areasId
        })
        this.areasListOne = targetList
        // 添加到下拉列表中
        const target = this.optionList.areasList.find((item) => {
          return item.areasId === areasId
        })
        if (target) {
          // 将当前条与小option比对，没有则加入
          if (
            this.areasListOne.every((item) => item.areasId !== target.areasId)
          ) {
            this.areasListOne.unshift(target)
          }
        }
      },
      resetForm(formName) {
        this.datekey = Date.now()
        this.$refs[formName].resetFields()
        this.form.pick = {
          provinceId: '',
          province: '',
          cityId: '',
          city: '"',
          areas: '',
          areasId: '',
          address: '',
          place: '',
          street: '',
          streetId: '',
          uqKey: '',
        }
      },
      async querySearch(queryString, cb) {
        this.cbFun = cb
        var restaurants = this.placeListOne
        cb(restaurants)
      },
      placeClickOne(domain) {
        this.placeAnalysis(this.placeObject)
        this.visibleThree = false
      },
      placeAnalysis(list) {
        var data = this.optionList.areasList.find(
          (item) => item.uqKey == list.uqKey
        )
        this.addValueOptions(list.areasId)
        // this.areasFilterOne(list.areasName)
        this.form.pick.provinceId = data.provinceId
        this.form.pick.province = data.provinceName
        this.form.pick.cityId = data.cityId
        this.form.pick.city = data.cityName
        this.form.pick.areas = data.areasName
        this.form.pick.areasId = data.areasId
        this.form.pick.uqKey = data.uqKey
        this.form.pick.streetId = data.streetId
        this.form.pick.street = data.streetName
        this.form.pick.address = list.address + '(' + list.name + ')'
        this.form.pick.place = this.placeObject.place
      },
      handleSelect(list) {
        this.$set(list, 'place', this.placeObject.place)
        this.placeObject = list
        //取消弹框提示
        // if (this.form.pick.uqKey == '') {
        this.placeAnalysis(list)
        // } else {
        //   this.visibleThree = true
        // }
      },
      async hendQuery(domain) {
        this.placeObject.place = domain
        const res = await areasPlaceSuggestion({ place: domain })
        this.placeListOne = res.data
        this.querySearch(domain, this.cbFun)
      },
      //提货地址
      onSelectAreasList(uqKey) {
        var data = this.optionList.areasList.find((item) => item.uqKey == uqKey)
        this.form.pick.provinceId = data.provinceId
        this.form.pick.province = data.provinceName
        this.form.pick.cityId = data.cityId
        this.form.pick.city = data.cityName
        this.form.pick.areas = data.areasName
        this.form.pick.areasId = data.areasId
        this.form.pick.streetId = data.streetId
        this.form.pick.street = data.streetName
        this.form.pick.uqKey = uqKey
        console.log(this.form.pick)
      },
      dianji() {
        console.log(this.form)
      },
      handleFold() {
        this.fold = !this.fold
      },
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      async confirmEdit(row) {
        row.edit = false
        console.log(row)
        let data = {
          code: row.code,
          totalSellPrice: row.totalSellPrice,
          inquiryCode: row.inquiryCode,
        }
        try {
          const res = await trailCarCostProductSelectEdit(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        } catch (error) {
          this.$baseMessage(res.msg, 'error', 'vab-hey-message-error')
        }
      },
      async queryList() {
        console.log('form', this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.listLoading = true
            try {
              const res = await trailCarServiceProductSelect(this.form)
              this.list = res.data.map((item) => {
                return {
                  ...item,
                  edit: false,
                }
              })
              this.listLoading = false
            } catch (error) {
              this.listLoading = false
              this.list = []
            }
            // this.total = res.data.totalSize
          }
        })
      },
      totalCalculation(form) {
        console.log(form)
        this.form.weightMap = form
      },
      totalCalculation2(form) {
        this.form.volumeMap = form
      },
      selectInputChang(form) {
        console.log('list', form)
        this.form.cabinetList = form
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__header {
      padding: 0px;
    }
    .el-dialog__body {
      padding: 20px;
    }
    .el-input--small .el-input__inner {
      border-radius: 5px;
    }
    .el-autocomplete-suggestion {
      width: auto !important;
    }
    .el-form--inline .el-form-item {
      padding-bottom: 10px;
    }
  }
</style>
