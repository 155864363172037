<template>
  <div>
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="form"
          :rules="rules"
          @submit.native.prevent
        >
          <div>
            <el-form-item label="客户" prop="customer">
              <!-- <el-input
              v-model.trim="form.customer"
              clearable
              maxlength="50"
              placeholder="请输入客户"
            /> -->
              <el-autocomplete
                v-model="form.customer"
                class="inline-input"
                clearable
                :fetch-suggestions="querySearchKe"
                placeholder="请输入内容"
                style="width: 150px"
                @select="handleSelectKe"
              >
                <template slot-scope="{ item }">
                  <div>
                    {{ item.name }}
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="业务员" prop="salesmanId">
              <el-select
                v-model="form.salesmanId"
                clearable
                filterable
                placeholder="请选择"
                style="width: 150px"
                @change="optionClickOne"
              >
                <el-option
                  v-for="item in optionList.salesmanGetValidList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="包装方式" prop="packagingMethodId">
              <el-select
                v-model="form.packagingMethodId"
                clearable
                filterable
                placeholder="请选择"
                style="width: 150px"
                @change="optionClickTwo"
              >
                <el-option
                  v-for="item in optionList.getValidList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="" label-width="60px" prop="remark">
              <div style="margin-left: 15px">
                备注
                <el-tooltip
                  class="item"
                  content="询价信息的补充"
                  effect="dark"
                  placement="top"
                >
                  <i class="el-icon-warning"></i>
                </el-tooltip>
                <el-input
                  v-model="form.remark"
                  maxlength="200"
                  placeholder="请输入内容"
                  :rows="1"
                  style="width: 330px"
                  type="textarea"
                />
              </div>
            </el-form-item>
            <el-form-item label="线路距离" prop="logisticsDistance">
              <el-input
                v-model.trim="form.logisticsDistance"
                clearable
                maxlength="12"
                oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                placeholder="线路距离（Km）"
                style="width: 150px"
                @blur="form.logisticsDistance = $event.target.value"
              />
              Km
            </el-form-item>
          </div>
          <div>
            <el-form-item label="" prop="inquiryPick.uqKey">
              <div style="margin-left: 5px; display: inline">
                提货地址
                <el-tooltip
                  class="item"
                  content="“东莞市/中山市/东沙群岛/嘉峪关市/晋城市/济源市，这类特殊地址只能解析为市辖区。若需更详细的地址，请手动选择”"
                  effect="dark"
                  placement="top"
                >
                  <i class="el-icon-warning"></i>
                </el-tooltip>
                <el-autocomplete
                  v-model="form.inquiryPick.place"
                  :fetch-suggestions="querySearch"
                  placeholder="输入待解析的地址"
                  :popper-append-to-body="false"
                  popper-class="my-autocomplete"
                  style="width: 150px"
                  @change="hendQuery(form.inquiryPick.place)"
                  @select="handleSelect($event)"
                >
                  <template slot-scope="{ item }">
                    <div style="color: #409eff">
                      {{ item.name }}
                    </div>
                    <div class="name" style="font-size: 10px">
                      {{ item.cityName
                      }}{{ item.areasName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                        item.address
                      }}
                    </div>
                    <span class="addr"></span>
                    <span class="addr"></span>
                  </template>
                </el-autocomplete>
              </div>
              <el-select
                v-model="form.inquiryPick.uqKey"
                :filter-method="areasFilterOne"
                filterable
                placeholder="请选择"
                style="margin: 0px 5px; width: 200px"
                @change="onSelectAreasList(form.inquiryPick.uqKey)"
              >
                <el-option
                  v-for="item in areasListOne"
                  :key="item.uqKey"
                  :label="item.fullName"
                  :value="item.uqKey"
                />
              </el-select>
              <!-- <el-popover placement="bottom" width="160" v-model="visibleThree">
                <p>警告：</p>
                <p>新解析的值将覆盖已存在的值</p>
                <div style="text-align: right; margin: 0">
                  <el-button
                    size="mini"
                    type="text"
                    @click="visibleThree = false"
                  >
                    取消
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="placeClickOne()"
                  >
                    确定
                  </el-button>
                </div>
              </el-popover> -->
              <el-input
                v-model="form.inquiryPick.address"
                clearable
                maxlength="100"
                placeholder="详细地址"
                style="width: 200px"
              />
            </el-form-item>
            <el-form-item label="" prop="inquiryDispatch.uqKey">
              <div style="margin-left: 5px; display: inline">
                送货地址
                <el-tooltip
                  class="item"
                  content="“东莞市/中山市/东沙群岛/嘉峪关市/晋城市/济源市，这类特殊地址只能解析为市辖区。若需更详细的地址，请手动选择”"
                  effect="dark"
                  placement="top"
                >
                  <i class="el-icon-warning"></i>
                </el-tooltip>
                <el-autocomplete
                  v-model="form.inquiryDispatch.place"
                  :fetch-suggestions="querySearchTwo"
                  placeholder="输入待解析的地址"
                  :popper-append-to-body="false"
                  popper-class="my-autocomplete"
                  style="width: 150px"
                  @change="hendQueryTwo(form.inquiryDispatch.place)"
                  @select="handleSelectTwo($event)"
                >
                  <template slot-scope="{ item }">
                    <div style="color: #409eff">
                      {{ item.name }}
                    </div>
                    <div class="name" style="font-size: 10px">
                      {{ item.cityName
                      }}{{ item.areasName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                        item.address
                      }}
                    </div>
                    <span class="addr"></span>
                    <span class="addr"></span>
                  </template>
                </el-autocomplete>
              </div>
              <el-select
                v-model="form.inquiryDispatch.uqKey"
                :filter-method="areasFilterTwo"
                filterable
                placeholder="请选择"
                style="margin: 0px 5px; width: 200px"
                @change="onSelectAreasListTwo(form.inquiryDispatch.uqKey)"
              >
                <el-option
                  v-for="item in areasListTwo"
                  :key="item.uqKey"
                  :label="item.fullName"
                  :value="item.uqKey"
                />
              </el-select>
              <!-- <el-popover placement="bottom" width="160" v-model="visibleFour">
                <p>警告：</p>
                <p>新解析的值将覆盖已存在的值</p>
                <div style="text-align: right; margin: 0">
                  <el-button
                    size="mini"
                    type="text"
                    @click="visibleFour = false"
                  >
                    取消
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="placeClickTwo()"
                  >
                    确定
                  </el-button>
                </div>
              </el-popover> -->
              <el-input
                v-model="form.inquiryDispatch.address"
                clearable
                maxlength="100"
                placeholder="详细地址"
                style="width: 200px"
              />
            </el-form-item>
          </div>
          <el-form-item v-show="fold" label="重量" prop="weight">
            <automatic-calculation
              :key="datekey"
              @totalCalculation="totalCalculation"
            />
          </el-form-item>

          <el-form-item
            v-show="fold"
            label="体积"
            label-width="60px"
            prop="volume"
          >
            <volume-calculation
              :key="datekey"
              @totalCalculation2="totalCalculation2"
            />
          </el-form-item>

          <el-form-item>
            <el-button type="text" @click="handleFold">
              <span v-if="fold">合并</span>
              <span v-else>展开</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': !fold }"
                icon="arrow-up-s-line"
              />
            </el-button>
          </el-form-item>
          <el-button type="primary" @click="queryList">查询</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      class="table eleChange"
      :data="list"
      element-loading-text="拼命加载中"
      header-cell-class-name="table-header"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="物流方案" prop="logisticsType" />
      <el-table-column align="center" label="提货地址">
        <el-table-column align="center" label="起运市" prop="pickCityName" />
        <el-table-column align="center" label="起运区" prop="pickAreasName" />
        <el-table-column
          align="center"
          label="起运街道"
          prop="pickStreetName"
        />
      </el-table-column>
      <el-table-column align="center" label="送货地址">
        <el-table-column
          align="center"
          label="目的市"
          prop="dispatchCityName"
        />
        <el-table-column
          align="center"
          label="目的区"
          prop="dispatchAreasName"
        />
        <el-table-column
          align="center"
          label="目的街道"
          prop="dispatchStreetName"
        />
      </el-table-column>
      <el-table-column
        align="center"
        label="总公里数"
        prop="logisticsDistance"
      />
      <el-table-column align="center" label="总销售价" width="120">
        <template #default="{ row }">
          <template v-if="row.edit">
            <el-input
              v-model.trim="row.totalSellPrice"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              @blur="row.totalSellPrice = $event.target.value"
              @keyup.enter.native="confirmEdit(row)"
            />
            <!-- <el-button
              class="cancel-btn"
              type="warning"
              @click="cancelEdit(row)"
            >
              取消
            </el-button> -->
          </template>
          <span v-else>{{ row.totalSellPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="总标准价"
        prop="totalStandardPrice"
      />
      <el-table-column align="center" label="车型" prop="truckType" />
      <el-table-column
        align="center"
        label="标准价明细"
        prop="standardDesc"
        width="250"
      >
        <template slot="header">
          标准价明细
          <el-tooltip
            content="城配产品不提供标准价明细"
            effect="dark"
            placement="top"
          >
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="单询服务"
        prop="singleInquiryService"
      >
        <template slot-scope="scope">
          <span>
            <el-tag type="danger">{{ scope.row.singleInquiryService }}</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="是否含税"
        prop="includingTaxesName"
      />
      <el-table-column align="center" label="时效" prop="ageing" />
      <el-table-column
        align="center"
        label="备注"
        prop="remark"
        show-overflow-tooltip
        width="350"
      />
      <el-table-column align="center" label="生效日" prop="effectiveDate" />
      <el-table-column align="center" label="失效日" prop="expiringDate" />
      <el-table-column align="center" label="产品编号" prop="code" />
      <el-table-column align="center" label="询价编号" prop="inquiryCode" />
      <el-table-column
        align="center"
        fixed="right"
        label="产品专员"
        prop="productCommissioner"
      />
      <!-- <el-table-column
        align="center"
        prop="dealCustomerList"
        label="头部成交客户"
      /> -->
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template #default="{ row }">
          <el-button
            v-if="row.edit"
            size="small"
            type="success"
            @click="confirmEdit(row)"
          >
            保存
          </el-button>
          <el-button
            v-else
            icon="el-icon-edit"
            size="small"
            type="primary"
            @click="row.edit = !row.edit"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <!-- <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    /> -->
  </div>
</template>
<script>
  import {
  areasPlaceSuggestion,
  trailCarCostProductSelectEdit,
} from '@/api/inquiryManagement';
import { productQueryBulkCargoSelect } from '@/api/productCenter';
import automaticCalculation from '@/components/automaticCalculation';
import volumeCalculation from '@/components/volumeCalculation';
  export default {
    components: {
      automaticCalculation,
      volumeCalculation,
    },
    props: {
      optionList: Object,
    },
    data() {
      return {
        fold: true,
        listLoading: false,
        visibleThree: false,
        visibleFour: false,
        height: this.$baseTableHeight(2),
        list: [],
        addressOne: '',
        addressTwo: '',
        datekey: Date.now(),
        form: {
          customer: '',
          salesmanId: '',
          salesman: '',
          remark: '',
          logisticsDistance: '',
          inquiryPick: {
            provinceId: '',
            province: '',
            cityId: '',
            city: '',
            areasId: '',
            areas: '',
            address: '',
            place: '',
            street: '',
            streetId: '',
            uqKey: '',
          },
          inquiryDispatch: {
            provinceId: '',
            province: '',
            cityId: '',
            city: '',
            areasId: '',
            areas: '',
            address: '',
            place: '',
            street: '',
            streetId: '',
            uqKey: '',
          },
          packagingMethodId: '',
          packagingMethod: '',
          weight: '',
          weightUnit: '',
          originalWeight: '',
          originalWeightUnit: '',
          volume: '',
          volumeUnit: '',
          originalVolume: '',
          originalVolumeUnit: '',
          // pageNum: 1,
          // pageSize: 10,
        },
        rules: {
          // customer: [
          //   { required: true, trigger: 'blur', message: '请输入客户' },
          //   { required: true, trigger: 'change', message: '请输入客户' },
          // ],
          logisticsDistance: [
            { required: true, trigger: 'blur', message: '请输入线路距离' },
          ],
          inquiryPick: {
            uqKey: [
              {
                required: true,
                trigger: 'change',
                message: '请选择提货地址',
              },
            ],
          },
          inquiryDispatch: {
            uqKey: [
              {
                required: true,
                trigger: 'change',
                message: '请选择送货地址',
              },
            ],
          },
          // packagingMethodId: [
          //   { required: true, trigger: 'change', message: '请选择包装方式' },
          // ],
          weight: [
            { required: true, trigger: 'change', message: '请输入重量' },
          ],
          volume: [
            { required: true, trigger: 'change', message: '请输入体积' },
          ],
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        cbFun: null,
        placeObject: {}, //解析地址
        placeObjectTwo: {}, //解析地址
        placeListOne: [],
        areasListOne: [], //提货地址
        areasListTwo: [], //送货地址
        placeListTwo: [],
      }
    },
    mounted() {
      // this.queryList()
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    methods: {
      querySearchKe(queryString, cb) {
        var restaurants = this.optionList.costomerGetValidList
        var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants
        // 调用 callback 返回建议列表的数据
        cb(results)
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (
            restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0
          )
        }
      },
      handleSelectKe(val) {
        this.form.customer = val.name
      },
      resetForm(formName) {
        this.datekey = Date.now()
        this.addressOne = ''
        this.addressTwo = ''
        this.$refs[formName].resetFields()
        this.form.inquiryPick = {
          provinceId: '',
          province: '',
          cityId: '',
          city: '',
          areasId: '',
          areas: '',
          address: '',
          place: '',
          street: '',
          streetId: '',
          uqKey: '',
        }
        this.form.inquiryDispatch = {
          provinceId: '',
          province: '',
          cityId: '',
          city: '',
          areasId: '',
          areas: '',
          address: '',
          place: '',
          street: '',
          streetId: '',
          uqKey: '',
        }
      },

      areasFilterOne(query = '') {
        let arr = this.optionList.areasList.filter((item) => {
          return item.fullName.includes(query) || item.fullName.includes(query)
        })
        if (arr.length > 150) {
          this.areasListOne = arr.slice(0, 150)
        } else {
          this.areasListOne = arr
        }
      },
      addValueOptions(areasId = '') {
        console.log(areasId)
        const targetList = this.optionList.areasList.filter((item) => {
          return item.areasId === areasId
        })
        this.areasListOne = targetList
        // 添加到下拉列表中
        const target = this.optionList.areasList.find((item) => {
          return item.areasId === areasId
        })
        console.log(target)
        if (target) {
          // 将当前条与小option比对，没有则加入
          if (
            this.areasListOne.every((item) => item.areasId !== target.areasId)
          ) {
            this.areasListOne.unshift(target)
          }
        }
        // console.log(target);
      },
      areasFilterTwo(query = '') {
        let arr = this.optionList.areasList.filter((item) => {
          return item.fullName.includes(query) || item.fullName.includes(query)
        })
        if (arr.length > 150) {
          this.areasListTwo = arr.slice(0, 150)
        } else {
          this.areasListTwo = arr
        }
      },
      addValueOptionsTwo(areasId) {
        const targetList = this.optionList.areasList.filter((item) => {
          return item.areasId === areasId
        })
        this.areasListTwo = targetList
        // 添加到下拉列表中
        const target = this.optionList.areasList.find((item) => {
          return item.areasId === areasId
        })
        if (target) {
          // 将当前条与小option比对，没有则加入
          if (
            this.areasListTwo.every((item) => item.areasId !== target.areasId)
          ) {
            this.areasListTwo.unshift(target)
          }
        }
      },
      async querySearch(queryString, cb) {
        this.cbFun = cb
        var restaurants = this.placeListOne
        cb(restaurants)
      },
      placeClickOne(domain) {
        this.placeAnalysis(this.placeObject)
        this.visibleThree = false
      },
      placeAnalysis(list) {
        console.log(list)
        var data = this.optionList.areasList.find(
          (item) => item.uqKey == list.uqKey
        )
        this.addValueOptions(list.areasId)
        // this.areasFilterOne(list.areasName)
        this.form.inquiryPick.uqKey = data.uqKey
        this.form.inquiryPick.provinceId = data.provinceId
        this.form.inquiryPick.province = data.provinceName
        this.form.inquiryPick.cityId = data.cityId
        this.form.inquiryPick.city = data.cityName
        this.form.inquiryPick.areas = data.areasName
        this.form.inquiryPick.areasId = data.areasId
        this.form.inquiryPick.streetId = data.streetId
        this.form.inquiryPick.street = data.streetName
        this.form.inquiryPick.address = list.address + '(' + list.name + ')'
        this.form.inquiryPick.place = this.placeObject.place
      },
      handleSelect(list) {
        this.$set(list, 'place', this.placeObject.place)
        this.placeObject = list
        // if (this.form.inquiryPick.uqKey == '') {
        this.placeAnalysis(list)
        // } else {
        // this.visibleThree = true
        // }
      },
      async hendQuery(domain) {
        this.placeObject.place = domain
        const res = await areasPlaceSuggestion({ place: domain })
        this.placeListOne = res.data
        this.querySearch(domain, this.cbFun)
      },
      onSelectAreasList(uqKey) {
        // console.log(id);
        var data = this.optionList.areasList.find((item) => item.uqKey == uqKey)
        this.form.inquiryPick.provinceId = data.provinceId
        this.form.inquiryPick.province = data.provinceName
        this.form.inquiryPick.cityId = data.cityId
        this.form.inquiryPick.city = data.cityName
        this.form.inquiryPick.areas = data.areasName
        this.form.inquiryPick.areasId = data.areasId
        this.form.inquiryPick.streetId = data.streetId
        this.form.inquiryPick.street = data.streetName
        this.form.inquiryPick.uqKey = uqKey
      },

      placeClickTwo(domain) {
        this.placeAnalysisTwo(this.placeObjectTwo)
        this.visibleFour = false
      },

      placeAnalysisTwo(list) {
        var data = this.optionList.areasList.find(
          (item) => item.uqKey == list.uqKey
        )
        this.addValueOptionsTwo(list.areasId)
        // this.areasFilterTwo(list.areasName)
        this.form.inquiryDispatch.provinceId = data.provinceId
        this.form.inquiryDispatch.province = data.provinceName
        this.form.inquiryDispatch.cityId = data.cityId
        this.form.inquiryDispatch.city = data.cityName
        this.form.inquiryDispatch.areas = data.areasName
        this.form.inquiryDispatch.areasId = data.areasId
        this.form.inquiryDispatch.streetId = data.streetId
        this.form.inquiryDispatch.street = data.streetName
        this.form.inquiryDispatch.uqKey = data.uqKey
        // this.form.inquiryDispatch.place = this.placeObjectTwo.place
        this.form.inquiryDispatch.address = list.address + '(' + list.name + ')'
        this.form.inquiryDispatch.place = this.placeObjectTwo.place
      },
      async querySearchTwo(queryString, cb) {
        this.cbFun = cb
        var restaurants = this.placeListTwo
        cb(restaurants)
      },
      handleSelectTwo(list) {
        this.$set(list, 'place', this.placeObjectTwo.place)
        this.placeObjectTwo = list
        // if (this.form.inquiryDispatch.uqKey == '') {
        this.placeAnalysisTwo(list)
        // } else {
        // this.visibleFour = true
        // }
      },
      async hendQueryTwo(domain) {
        this.placeObjectTwo.place = domain
        const res = await areasPlaceSuggestion({ place: domain })
        this.placeListTwo = res.data
        this.querySearchTwo(domain, this.cbFun)
      },

      onSelectAreasListTwo(uqKey) {
        var data = this.optionList.areasList.find((item) => item.uqKey == uqKey)
        this.form.inquiryDispatch.provinceId = data.provinceId
        this.form.inquiryDispatch.province = data.provinceName
        this.form.inquiryDispatch.cityId = data.cityId
        this.form.inquiryDispatch.city = data.cityName
        this.form.inquiryDispatch.areas = data.areasName
        this.form.inquiryDispatch.areasId = data.areasId
        this.form.inquiryDispatch.streetId = data.streetId
        this.form.inquiryDispatch.street = data.streetName
        this.form.inquiryDispatch.uqKey = uqKey
      },
      dianji() {
        console.log(this.form)
      },
      handleFold() {
        this.fold = !this.fold
      },
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      // handleSizeChange(val) {
      //   this.form.pageNum = 1
      //   this.form.pageSize = val
      //   this.queryList()
      // },
      // handleCurrentChange(val) {
      //   this.form.pageNum = val
      //   this.queryList()
      // },
      setSelectRows(val) {
        this.selectRows = val
      },
      async confirmEdit(row) {
        row.edit = false
        console.log(row)
        let data = {
          code: row.code,
          totalSellPrice: row.totalSellPrice,
          inquiryCode: row.inquiryCode,
        }
        try {
          const res = await trailCarCostProductSelectEdit(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        } catch (error) {
          this.$baseMessage(res.msg, 'error', 'vab-hey-message-error')
        }
      },
      async queryList() {
        console.log('form', this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.listLoading = true
            try {
              const res = await productQueryBulkCargoSelect(this.form)
              this.list = res.data.map((item) => {
                return {
                  ...item,
                  edit: false,
                }
              })
              this.listLoading = false
            } catch (error) {
              this.listLoading = false
              this.list = []
            }

            // this.total = res.data.totalSize
          }
        })
      },
      optionClickOne(id) {
        if (id) {
          var data = this.optionList.salesmanGetValidList.find(
            (item) => item.id == id
          )
          this.form.salesmanId = data.id
          this.form.salesman = data.name
        }
      },
      optionClickTwo(id) {
        if (id) {
          var data = this.optionList.getValidList.find((item) => item.id == id)
          this.form.packagingMethodId = data.id
          this.form.packagingMethod = data.name
        }
      },
      totalCalculation(weight, weightUnit, originalWeight, originalWeightUnit) {
        this.form.weight = weight
        this.form.weightUnit = weightUnit
        this.form.originalWeight = originalWeight
        this.form.originalWeightUnit = originalWeightUnit
      },
      totalCalculation2(
        volume,
        volumeUnit,
        originalVolume,
        originalVolumeUnit
      ) {
        this.form.volume = volume
        this.form.volumeUnit = volumeUnit
        this.form.originalVolume = originalVolume
        this.form.originalVolumeUnit = originalVolumeUnit
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__header {
      padding: 0px;
    }
    .el-dialog__body {
      padding: 20px;
    }
    .el-input--small .el-input__inner {
      border-radius: 5px;
    }
    .el-autocomplete-suggestion {
      width: auto !important;
    }
    .el-form--inline .el-form-item {
      padding-bottom: 10px;
    }
  }
</style>
