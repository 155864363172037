<template>
  <div class="fomrDisply">
    <div>
      <el-input
        v-model.trim="form.volume"
        clearable
        oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/,'$1')"
        style="width: 200px"
        @blur="inputClick"
        @input="inputClick"
        slot="reference"
      >
        <!-- @blur="form.volume = $event.target.value" -->
        <template slot="append">CBM</template>
      </el-input>
      <!-- <el-popover placement="bottom" width="160" v-model="visible">
        <p>警告：</p>
        <p>新解析的值将覆盖已存在的值</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="visible = false">
            取消
          </el-button>
          <el-button type="primary" size="mini" @click="weightDataTwo()">
            确定
          </el-button>
        </div>
      </el-popover> -->
    </div>
    <div style="padding-left: 10px">
      <el-input
        v-model.trim="form.originalVolume"
        class="input-with-select"
        clearable
        oninput="value=value.replace(/[^0-9+().*%/-]/g,'')"
        placeholder="请输入数字和运算符，乘号只能用*"
        style="width: 250px"
        @blur="form.originalVolume = $event.target.value"
        @keyup.enter.native="inputChangeOne"
      />
      <el-select
        slot="prepend"
        v-model="form.originalVolumeUnit"
        placeholder="请选择"
        style="width: 80px"
        @change="inputChangeOne"
      >
        <el-option label="CBM" value="CBM" />
        <el-option label="M" value="M" />
        <el-option label="CM" value="CM" />
        <el-option label="MM" value="MM" />
      </el-select>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //   number: Number,
        //   originalVolume: String,
        visible: false,
        form: {
          volumeUnit: 'CBM',
          volume: '',
          originalVolume: '',
          originalVolumeUnit: 'CBM',
        },
      }
    },
    //   props: {
    //     'volume': {
    //         type: Number,
    //         debugger: 0
    //     },
    //     'originalVolume': {
    //         type: String,
    //         debugger: ''
    //     }
    //   },
    methods: {
      weightDataTwo() {
        if (
          this.form.originalVolumeUnit == 'CBM' &&
          this.form.originalVolume != ''
        ) {
          this.form.volume = eval(this.form.originalVolume).toFixed(3)
        } else if (
          this.form.originalVolumeUnit == 'M' &&
          this.form.originalVolume != ''
        ) {
          this.form.volume = eval(this.form.originalVolume).toFixed(3)
        } else if (
          this.form.originalVolumeUnit == 'CM' &&
          this.form.originalVolume != ''
        ) {
          this.form.volume = (
            eval(this.form.originalVolume) * 0.000001
          ).toFixed(3)
        } else if (
          this.form.originalVolumeUnit == 'MM' &&
          this.form.originalVolume != ''
        ) {
          this.form.volume = (
            eval(this.form.originalVolume) * 0.000000001
          ).toFixed(3)
          console.log(eval(this.form.originalVolume) * 0.000000001)
          console.log(this.form.volume)
        } else if (this.form.originalVolume != '') {
          this.form.volume = eval(this.form.originalVolume).toFixed(3)
        }
        this.visible = false
        // this.$emit('totalCalculation2', this.form)
        this.$emit(
          'totalCalculation2',
          this.form.volume,
          this.form.volumeUnit,
          this.form.originalVolume,
          this.form.originalVolumeUnit
        )
      },
      inputChangeOne() {
        // if (this.form.volume == '' || this.form.volume == undefined) {
        this.weightDataTwo()
        // } else if (this.form.originalVolume != '') {
        // this.visible = true
        // }
      },

      // inputChangeOne() {
      //   if (
      //     this.form.originalVolumeUnit == 'CBM' &&
      //     this.form.originalVolume != ''
      //   ) {
      //     this.form.volume = eval(this.form.originalVolume).toFixed(3)
      //   } else if (
      //     this.form.originalVolumeUnit == 'M' &&
      //     this.form.originalVolume != ''
      //   ) {
      //     this.form.volume = eval(this.form.originalVolume).toFixed(3)
      //   } else if (
      //     this.form.originalVolumeUnit == 'CM' &&
      //     this.form.originalVolume != ''
      //   ) {
      //     this.form.volume = (eval(this.form.originalVolume) * 0.000001).toFixed(
      //       3
      //     )
      //   } else if (
      //     this.form.originalVolumeUnit == 'MM' &&
      //     this.form.originalVolume != ''
      //   ) {
      //     this.form.volume = (
      //       eval(this.form.originalVolume) * 0.000000001
      //     ).toFixed(3)
      //     console.log(eval(this.form.originalVolume) * 0.000000001)
      //     console.log(this.form.volume)
      //   } else if (this.form.originalVolume != '') {
      //     this.form.volume = eval(this.form.originalVolume).toFixed(3)
      //   }
      //   this.visible = false
      //   this.$emit('totalCalculation2', this.form)
      // },
      inputClick() {
        // this.$emit('totalCalculation2', this.form)
        this.$emit(
          'totalCalculation2',
          this.form.volume,
          this.form.volumeUnit,
          this.form.originalVolume,
          this.form.originalVolumeUnit
        )
      },
    },
  }
</script>

<style></style>
