var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fomrDisply" }, [
    _c(
      "div",
      [
        _c(
          "el-input",
          {
            staticStyle: { width: "200px" },
            attrs: {
              slot: "reference",
              clearable: "",
              oninput:
                "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,3})?.*$/,'$1')"
            },
            on: { blur: _vm.inputClick, input: _vm.inputClick },
            slot: "reference",
            model: {
              value: _vm.form.weight,
              callback: function($$v) {
                _vm.$set(
                  _vm.form,
                  "weight",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.weight"
            }
          },
          [_c("template", { slot: "append" }, [_vm._v(" KG  ")])],
          2
        )
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "padding-left": "10px" } },
      [
        _c("el-input", {
          staticClass: "input-with-select",
          staticStyle: { width: "250px" },
          attrs: {
            clearable: "",
            oninput: "value=value.replace(/[^0-9+().*%/-]/g,'')",
            placeholder: "请输入数字和运算符，乘号只能用*"
          },
          on: {
            blur: function($event) {
              _vm.form.originalWeight = $event.target.value
            }
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.inputChangeOne.apply(null, arguments)
            }
          },
          model: {
            value: _vm.form.originalWeight,
            callback: function($$v) {
              _vm.$set(
                _vm.form,
                "originalWeight",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "form.originalWeight"
          }
        }),
        _c(
          "el-select",
          {
            staticStyle: { width: "80px" },
            attrs: { slot: "prepend", placeholder: "请选择" },
            on: { change: _vm.inputChangeOne },
            slot: "prepend",
            model: {
              value: _vm.form.originalWeightUnit,
              callback: function($$v) {
                _vm.$set(_vm.form, "originalWeightUnit", $$v)
              },
              expression: "form.originalWeightUnit"
            }
          },
          [
            _c("el-option", { attrs: { label: "KG", value: "KG" } }),
            _c("el-option", { attrs: { label: "T", value: "T" } })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }