var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    "label-width": "80px",
                    model: _vm.form,
                    rules: _vm.rules
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户", prop: "customer" } },
                        [
                          _c("el-autocomplete", {
                            staticClass: "inline-input",
                            staticStyle: { width: "150px" },
                            attrs: {
                              clearable: "",
                              "fetch-suggestions": _vm.querySearchKe,
                              placeholder: "请输入内容"
                            },
                            on: { select: _vm.handleSelectKe },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("div", [
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ])
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.form.customer,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customer", $$v)
                              },
                              expression: "form.customer"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员", prop: "salesmanId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择"
                              },
                              model: {
                                value: _vm.form.salesmanId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "salesmanId", $$v)
                                },
                                expression: "form.salesmanId"
                              }
                            },
                            _vm._l(
                              _vm.optionList.salesmanGetValidList,
                              function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }
                            ),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            "label-width": "60px",
                            prop: "remark"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "15px" } },
                            [
                              _vm._v(" 备注 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "询价信息的补充",
                                    effect: "dark",
                                    placement: "top"
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-warning" })]
                              ),
                              _c("el-input", {
                                staticStyle: { width: "330px" },
                                attrs: {
                                  maxlength: "200",
                                  placeholder: "请输入内容",
                                  rows: 1,
                                  type: "textarea"
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "pick.uqKey" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "5px",
                                display: "inline"
                              }
                            },
                            [
                              _vm._v(" 提货地址 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content:
                                      "“东莞市/中山市/东沙群岛/嘉峪关市/晋城市/济源市，这类特殊地址只能解析为市辖区。若需更详细的地址，请手动选择”",
                                    effect: "dark",
                                    placement: "top"
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-warning" })]
                              ),
                              _c("el-autocomplete", {
                                staticStyle: { width: "150px" },
                                attrs: {
                                  "fetch-suggestions": _vm.querySearch,
                                  placeholder: "输入待解析的地址",
                                  "popper-append-to-body": false,
                                  "popper-class": "my-autocomplete"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.hendQuery(_vm.form.pick.place)
                                  },
                                  select: function($event) {
                                    return _vm.handleSelect($event)
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          { staticStyle: { color: "#409eff" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "name",
                                            staticStyle: { "font-size": "10px" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.cityName) +
                                                _vm._s(item.areasName) +
                                                "    " +
                                                _vm._s(item.address) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("span", { staticClass: "addr" }),
                                        _c("span", { staticClass: "addr" })
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.form.pick.place,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form.pick, "place", $$v)
                                  },
                                  expression: "form.pick.place"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                margin: "0px 5px",
                                width: "200px"
                              },
                              attrs: {
                                "filter-method": _vm.areasFilterOne,
                                filterable: "",
                                placeholder: "请选择"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onSelectAreasList(
                                    _vm.form.pick.uqKey
                                  )
                                }
                              },
                              model: {
                                value: _vm.form.pick.uqKey,
                                callback: function($$v) {
                                  _vm.$set(_vm.form.pick, "uqKey", $$v)
                                },
                                expression: "form.pick.uqKey"
                              }
                            },
                            _vm._l(_vm.areasListOne, function(item) {
                              return _c("el-option", {
                                key: item.uqKey,
                                attrs: {
                                  label: item.fullName,
                                  value: item.uqKey
                                }
                              })
                            }),
                            1
                          ),
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              clearable: "",
                              maxlength: "100",
                              placeholder: "详细地址"
                            },
                            model: {
                              value: _vm.form.pick.address,
                              callback: function($$v) {
                                _vm.$set(_vm.form.pick, "address", $$v)
                              },
                              expression: "form.pick.address"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "起运港", prop: "dispatch" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "130px" },
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择"
                              },
                              model: {
                                value: _vm.form.dispatch,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "dispatch", $$v)
                                },
                                expression: "form.dispatch"
                              }
                            },
                            _vm._l(_vm.optionList.portList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "柜型柜量", prop: "cabinetList" } },
                        [
                          _c("select-input", {
                            key: _vm.datekey,
                            on: { selectInputChang: _vm.selectInputChang }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fold,
                          expression: "fold"
                        }
                      ],
                      attrs: { label: "重量", prop: "weightMap" }
                    },
                    [
                      _c("trailer-automatic-calculation", {
                        key: _vm.datekey,
                        on: { totalCalculation: _vm.totalCalculation }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.handleFold }
                        },
                        [
                          _vm.fold
                            ? _c("span", [_vm._v("合并")])
                            : _c("span", [_vm._v("展开")]),
                          _c("vab-icon", {
                            staticClass: "vab-dropdown",
                            class: { "vab-dropdown-active": !_vm.fold },
                            attrs: { icon: "arrow-up-s-line" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.queryList }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.resetForm("form")
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "tableSort",
          staticClass: "table eleChange",
          attrs: {
            border: "",
            data: _vm.list,
            "element-loading-text": "拼命加载中",
            "header-cell-class-name": "table-header"
          },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "提货地址" } },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "起运市",
                  prop: "pickCityName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "起运区",
                  prop: "pickAreasName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "起运街道",
                  prop: "pickStreetName"
                }
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "送货地址" } },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "起运港",
                  prop: "dispatchPortName"
                }
              })
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { align: "center", label: "总销售价", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.edit
                      ? [
                          _c("el-input", {
                            attrs: {
                              oninput:
                                "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')"
                            },
                            on: {
                              blur: function($event) {
                                row.totalSellPrice = $event.target.value
                              }
                            },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.confirmEdit(row)
                              }
                            },
                            model: {
                              value: row.totalSellPrice,
                              callback: function($$v) {
                                _vm.$set(
                                  row,
                                  "totalSellPrice",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "row.totalSellPrice"
                            }
                          })
                        ]
                      : _c("span", [_vm._v(_vm._s(row.totalSellPrice))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "总标准价", prop: "standard" }
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "标准价" } },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "20GP", prop: "standard20gp" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "40GP", prop: "standard40gp" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "40HQ", prop: "standard40hq" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "45HQ", prop: "standard45hq" }
              })
            ],
            1
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "是否含税",
              prop: "includingTaxesName"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "附加费",
              prop: "surcharge",
              "show-overflow-tooltip": "",
              width: "350"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "时效", prop: "ageing" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "备注",
              prop: "remark",
              "show-overflow-tooltip": "",
              width: "350"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "生效日", prop: "effectiveDate" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "失效日", prop: "expiringDate" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "产品编号", prop: "code" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "询价编号", prop: "inquiryCode" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "产品专员",
              prop: "productCommissioner"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.confirmEdit(row)
                              }
                            }
                          },
                          [_vm._v(" 保存 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-edit",
                              size: "small",
                              type: "primary"
                            },
                            on: {
                              click: function($event) {
                                row.edit = !row.edit
                              }
                            }
                          },
                          [_vm._v(" 编辑 ")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }