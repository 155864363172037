<template>
  <div class="dashboard-container">
    <div>
      <el-tabs v-model="type" @tab-click="handleClick">
        <el-tab-pane label="散货" name="2">
          <trailer-products ref="table2" :option-list="optionList" />
        </el-tab-pane>
        <el-tab-pane label="拖车" name="1">
          <bulk-products ref="table1" :option-list="optionList" />
        </el-tab-pane>
        <!-- <el-tab-pane label="整车" name="3">
          <bulk-products ref="table1" :option-list="optionList" />
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import BulkProducts from './components/BulkProducts.vue'
import TrailerProducts from './components/TrailerProducts.vue'
import WholeVehicleProducts from './components/WholeVehicleProducts.vue'
  // import UrbanDistributionServices from './components/UrbanDistributionServices.vue'
  import { costomerGetValidList } from '@/api/businessReport'
import {
  getValidList,
  portGetValidList,
  salesmanGetValidList,
  streetAndAreasGetValidList,
} from '@/api/inquiryManagement'
  export default {
    name: 'ProductQuery',
    components: { TrailerProducts, BulkProducts, WholeVehicleProducts },
    data() {
      return {
        type: '2',
        optionList: {
          salesmanGetValidList: [], //业务员
          getValidList: [], //包装方式
          portList: [], //港口
          areasList: [], //省市区
          costomerGetValidList: [], //客户
        },
      }
    },
    mounted() {
      this.selectList()
    },
    methods: {
      handleClick() {},
      async selectList() {
        Promise.all(
          [
            salesmanGetValidList({}),
            getValidList({ typeId: '2' }),
            portGetValidList({}),
            streetAndAreasGetValidList({}),
            costomerGetValidList({}),
          ].map((v) => v.catch((e) => console.log(e)))
        ).then((res) => {
          this.optionList.salesmanGetValidList = res[0].data
          this.optionList.getValidList = res[1].data
          this.optionList.portList = res[2].data
          this.optionList.areasList = res[3].data
          this.optionList.costomerGetValidList = res[4].data
          this.$refs.table1.areasFilterOne()
          this.$refs.table2.areasFilterOne()
          this.$refs.table2.areasFilterTwo()
        })

        // const res = await salesmanGetValidList({})
        // this.optionList.salesmanGetValidList = res.data
        // const rew = await getValidList({typeId:'2'})
        // this.optionList.getValidList = rew.data
        // const rep = await portGetValidList({})
        // this.optionList.portList = rep.data
        // const req = await streetAndAreasGetValidList({})
        // this.optionList.areasList = req.data
        // const red = await costomerGetValidList({})
        // this.optionList.costomerGetValidList = red.data
        // this.$refs.table1.areasFilterOne()
        // this.$refs.table2.areasFilterOne()
        // this.$refs.table2.areasFilterTwo()
      },
    },
  }
</script>
<style lang="scss">
  .el-tooltip__popper {
    max-width: 20%;
  }
</style>
